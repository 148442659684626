import React from "react";
import HomeCard from "./home-card";
import * as HOMECARD from './home.card.constants'
import {googleContact} from "./home.card.constants";

export default function Home() {
    return (
        <div>
            <div className="py-5 d-md-flex flex-md-equal w-100 justify-content-center align-items-center text-center"
                 style={{width: "auto", minHeight: "400px"}}>
                <div
                    className="me-md-3 px-3 px-md-5 text-center d-flex flex-column align-items-center justify-content-center">
                    <h1 className="display-3 text-black mb-0 fw-bolder"> Empowering Children with Interrupted Education through
                        Algebra</h1>
                    <h3 className="pt-5 fw-bold text-white">HS algebra is the number one
                        predictor for
                        graduation and future success</h3>
                </div>

            </div>
            <div className="d-md-flex flex-md-equal w-100 ps-md-3 bg-light pt-5 pb-3">
                <div className="me-md-3 px-3 px-md-5 text-center me-md-3 px-3 px-md-5 text-center">
                    <h1 className="display-5 fw-bold text-black">Our Mission</h1>
                    <div className="py-3">
                        <p className="lead fw-bold">
                            Our mission is to provide equitable learning opportunities and enact meaningful change for
                            children with interrupted education.
                        </p>
                        <p className="lead">
                            Algebra plays a gatekeeper role to higher education. Taking and passing an algebra class
                            determines whether students can access higher level courses and graduate high school.
                            We at ReviveNY provide algebra solutions to address key learning roadblocks our students
                            face
                            today.
                        </p>
                    </div>
                </div>
            </div>
            <div className="d-md-flex flex-md-equal w-100 ps-md-3 bg-light pt-3 pb-3">
                <div className=" me-md-3 px-3 px-md-5 text-center">
                    <h2 className="display-5 text-black fw-bold">Our Program</h2>
                    <div className="py-3">
                        <p className="lead fw-bold">Through our program, both students and teachers can access
                            resources tailored to learners with gaps in their education.
                        </p>
                        <p className="lead">
                            ReviveNY offers video lessons, workbooks, standards aligned curriculum
                            maps, lesson plans, and assessments in a Spanish-English dual language platform.
                        </p>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col overflow-hidden">
                            <HomeCard imageName={HOMECARD.languageText.imageName}
                                      subtitle={HOMECARD.languageText.subtitle}
                                      text={HOMECARD.languageText.text}/>
                        </div>
                        <div className="col overflow-hidden">
                            <HomeCard imageName={HOMECARD.videoLessonsText.imageName}
                                      subtitle={HOMECARD.videoLessonsText.subtitle}
                                      text={HOMECARD.videoLessonsText.text}/>
                        </div>
                        <div className="col overflow-hidden">
                            <HomeCard imageName={HOMECARD.workbookText.imageName}
                                      subtitle={HOMECARD.workbookText.subtitle}
                                      text={HOMECARD.workbookText.text}/>
                        </div>
                        <div className="col overflow-hidden">
                            <HomeCard imageName={HOMECARD.curriculumMapText.imageName}
                                      subtitle={HOMECARD.curriculumMapText.subtitle}
                                      text={HOMECARD.curriculumMapText.text}/>
                        </div>
                        <div className="col overflow-hidden">
                            <HomeCard imageName={HOMECARD.assessmentsText.imageName}
                                      subtitle={HOMECARD.assessmentsText.subtitle}
                                      text={HOMECARD.assessmentsText.text}/>
                        </div>
                    </div>
                    <a className="btn fw-bolder btn-secondary" href={HOMECARD.googleContact}>Learn More</a>
                </div>
            </div>
        </div>
    );
}
