import dotenv from 'dotenv';
dotenv.config();

// Use the variables in your components...
const apiBaseUrl =  process.env.REACT_APP_API_URL;

console.log("=====");
console.log(apiBaseUrl);
console.log("=====");
//const apiBaseUrl = "https://reviveny.org:8443";

export default apiBaseUrl;

Object.freeze(apiBaseUrl);