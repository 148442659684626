import React, {useContext} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import AuthContext from './components/auth/AuthContext';
import LoginNext from "./components/login";
import {googleContact} from "./components/homepage/home.card.constants";
import "./css/bootstrap-minty.min.css";
import "./css/App.css";

import RegistrationComponent from "./components/registration.component";
import Home from "./components/homepage/home.component";
import Profile from "./components/profile.component";
import CourseHome from "./components/modules/course-home";
import CourseMainPage from "./components/main/course.main.page";

import CourseAdmin from "./components/admin/admin-crud-components/course-admin.component";
import UserCourseEnrollmentAdmin
    from "./components/admin/admin-crud-components/user-course-enrollment-status.component";
import UserAdmin from "./components/admin/admin-crud-components/user-admin.component";
import UserLessonStatusAdmin from "./components/admin/admin-crud-components/user-lesson-status.component";
import AdminHome from "./components/admin/admin-home.component";
import LessonCrud from "./components/admin/admin-crud-components/lesson-crud/lesson-crud";

function App(props) {

    const { getUser ,logout } = useContext(AuthContext);
    const user = getUser();

    return (
        <div id="container" className="container-fluid position-relative "
                     style={{ width: "auto", height: "50%", backgroundImage: `url('home_page_top.jpg')`}}>
            <BrowserRouter>
                <nav className="navbar navbar-expand-lg navbar-dark ">
                        <a href="/" className="navbar-brand ps-3 text-black fw-bold fs-1" id="logo">
                            ReviveNY
                        </a>

                        <div className="navbar-nav me-auto mb-2 mb-lg-0">
                            {user && (
                                <li className="nav-item">
                                    <a href="/courses
                                    " className="nav-link">
                                        My Courses
                                    </a>
                                </li>
                            )}
                        </div>

                        {user ? (
                            <div className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a href="/profile" className="nav-link">
                                        {user.username}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/admin" className="nav-link">
                                        Administrate
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/login" className="nav-link" onClick={logout}>
                                        LogOut
                                    </a>
                                </li>
                            </div>
                        ) : null}
                        <a className="btn btn-dark fw-bolder" href={googleContact}>Contact Us</a>

                    </nav>
                    <Routes>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/login" element={<LoginNext/>}/>
                        <Route path="/register" element={<RegistrationComponent/>}/>
                        <Route path="/profile" element={<Profile/>}/>

                        <Route path="/courses" element={<CourseMainPage/>}>
                            <Route index element={<CourseMainPage/>}/>
                        </Route>

                        <Route path="/courses/:courseName" element={<CourseHome/>}>
                            <Route path="/courses/:courseName/:lessonIndex" element={<CourseHome/>}/>
                        </Route>

                        <Route path="/admin" element={<AdminHome/>}/>
                        <Route path="/admin/courses" element={<CourseAdmin/>}/>
                        <Route path="/admin/enrollment" element={<UserCourseEnrollmentAdmin/>}/>
                        <Route path="/admin/users" element={<UserAdmin/>}/>
                        <Route path="/admin/lessons" element={<LessonCrud/>}/>
                        <Route path="/admin/lessonstatus" element={<UserLessonStatusAdmin/>}/>

                    </Routes>



            </BrowserRouter>
            <nav className="navbar navbar-dark bg-primary align-items-center justify-content-center">
                <a className="btn fw-bolder border-white" href={googleContact}>Contact Us</a>
            </nav>

        </div>
    );
}

export default App;

/*
: (
  <div className="navbar-nav ms-auto mb-2 mb-lg-0">
      <li className="nav-item">
          <a href="/login" className="nav-link">
              Login
          </a>
      </li>

      <li className="nav-item">
          <a href="/register" className="nav-link pe-3">
              Sign Up
          </a>
      </li>
  </div>*/